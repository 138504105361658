import { Container } from '@material-ui/core';
import React from 'react';

import { Layout, PrivacyCard, SEO } from '../components';

export default function Legal(props) {
  return (
    <Layout {...props}>
      <SEO title="Privacy" pathname={props.location.pathname} />
      <Container maxWidth="md">
        <PrivacyCard />
      </Container>
    </Layout>
  );
}
